<template>
  <section class="w-full bg-thirdry text-gray-200 flex justify-center lg:items-center relative">
    <div class="container p-10">
      <div class="w-full lg:text-2xl text-lg font-bold py-3 text-center">
        <p class="pb-2">
          e-mail:
          <a
            href="mailto:odkryj@goodwaylifestyle.com"
            class="text-secondary hover:text-primary underline"
          >odkryj@goodwaylifestyle.com</a>
        </p>
        <p class="py-3">
          Napełniaj się inspiracjami Good Way Life Discovery - zapisz się do
          newslettera
        </p>
      </div>
      <div class="flex justify-center">
        <form @submit.prevent="send" class="lg:w-1/2 w-full">
          <div class="mb-2">
            <input
              class="appearance-none block w-full bg-gray-100 md:text-sm text-black border-b-2 mb-1 p-2 leading-tight focus:outline-none focus:bg-white focus:border-primary"
              :class="errors.name ? 'border-secondary' : 'border-thirdry'"
              type="text"
              placeholder="Imię"
              v-model="form.name"
            />
          </div>
          <div class="mb-2">
            <input
              class="appearance-none block w-full bg-gray-100 md:text-sm text-black border-b-2 mb-1 p-2 leading-tight focus:outline-none focus:bg-white focus:border-primary"
              :class="errors.email ? 'border-secondary' : 'border-thirdry'"
              type="email"
              placeholder="E-mail"
              v-model="form.email"
            />
          </div>
          <div class="mb-2">
            <label class="w-full block text-justify" :class="{ 'text-secondary': errors.rodo }">
              <input
                class="mr-2 leading-tight form-checkbox"
                type="checkbox"
                v-model="form.rodo"
                true-value="Wyrażam zgodę"
                :false-value="null"
              />
              <span class="text-sm">
                Wyrażam zgodę na
                <router-link
                  to="rodo"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="hover:text-black underline"
                >zbieranie i&nbsp;przetwarzanie danych osobowych</router-link>&nbsp;oraz akceptuję
                <router-link
                  to="polityka-prywatnosci"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="hover:text-black underline"
                >politykę prywatności</router-link>&nbsp;serwisu.
              </span>
            </label>
          </div>
          <transition
            :duration="{ enter: 600, leave: 300 }"
            name="fade"
            mode="out-in"
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
          >
            <div class="bg-red-600 text-white text-center p-2 rounded-lg" v-if="error">
              <h4>Nie wszystkie dane zostały poprawnie wypełnione!</h4>
            </div>
          </transition>
          <transition
            :duration="{ enter: 600, leave: 300 }"
            name="fade"
            mode="out-in"
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
          >
            <div class="bg-green-500 text-white text-center p-2 rounded-lg" v-if="success">
              <h4>Dziękujemy, za zapisanie do naszego newslettera!</h4>
            </div>
          </transition>
          <transition
            :duration="{ enter: 600, leave: 300 }"
            name="fade"
            mode="out-in"
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
          >
            <div class="bg-green-500 text-white text-center p-2 rounded-lg" v-if="subs">
              <h4>Jesteś już naszym subskrybentem!</h4>
            </div>
          </transition>
          <div class="flex justify-center my-2">
            <button
              type="submit"
              class="mx-auto pulse rounded-full w-24 h-24 uppercase text-gray-200 bg-secondary font-sans text-sm tracking-wider"
            >Zapisz się</button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "Copy",
  data() {
    return {
      form: {},
      errors: {
        name: null,
        email: null,
        rodo: null
      },
      success: false,
      subs: false,
      error: false
    };
  },
  methods: {
    send() {
      axios
        .post("/api/send/mail", this.form)
        .then(response => {
          console.log(response.data);
          this.success = response.data.success;
          this.subs = response.data.subs;
          this.form = {};
          this.error = false;
          this.errors = {
            name: null,
            email: null,
            rodo: null
          };
          setTimeout(() => {
            this.success = false;
            this.subs = false;
          }, 10000);
        })
        .catch(error => {
          this.error = true;
          this.errors = error.response.data.errors;
        });
    }
  }
};
</script>
