<template>
  <section
    class="w-full bg-thirdry text-gray-200 flex justify-center items-center min-h-screen"
    id="biosec"
  >
    <div
      class="w-full py-10 text-gray-200 flex flex-col justify-center items-center"
      v-animate-onscroll="'animate__animated animate__fadeIn animate__slow'"
    >
      <div class="container px-10 lg:text-xl text-lg" v-html="text"></div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "BioSec",
  data() {
    return {
      text: ""
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await axios.get("/api/app/15").then(response => {
        this.text = response.data.text;
      });
    }
  }
};
</script>
