<template>
  <div div id="index" ref="index">
    <Bio />
    <div class="bg-primary text-gray-200 flex justify-center items-center w-full">
      <img class="lg:h-32 md:h-16" src="@/assets/img/logo.svg" alt="Good Way" />
    </div>
    <BioSec />
    <Copy />
    <Footer />
  </div>
</template>

<script>
import Bio from "@/components/Contact/Bio";
import BioSec from "@/components/Contact/BioSec";
import Copy from "@/components/Contact/Copy";
import Footer from "@/components/Theme/Footer";

export default {
  name: "Contact",
  components: {
    Bio,
    BioSec,
    Copy,
    Footer
  }
};
</script>
